





























import Vue from 'vue';
import NodeItem from './NodeItem.vue';
import CategoryItem from './CategoryItem.vue';
import SubcategoryItem from './SubcategoryItem.vue';

export default Vue.extend({
	name: 'CreatorItem',
	components: {
		CategoryItem,
		SubcategoryItem,
		NodeItem,
	},
	props: ['item', 'active', 'clickable', 'lastNode'],
});
