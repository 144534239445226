














































import Vue from 'vue';

import {
	ONBOARDING_CALL_SIGNUP_MODAL_KEY,
	VALID_EMAIL_REGEX,
} from '@/constants';
import Modal from './Modal.vue';

import mixins from 'vue-typed-mixins';
import { showMessage } from './mixins/showMessage';

export default mixins(
	showMessage,
).extend({
	components: {
		Modal,
	},
	name: 'OnboardingCallSignupModal',
	props: [ 'modalName' ],
	data() {
		return {
			email: '',
			modalBus: new Vue(),
			ONBOARDING_CALL_SIGNUP_MODAL_KEY,
			showError: false,
			okToClose: false,
			loading: false,
		};
	},
	computed: {
		isEmailValid(): boolean {
			return VALID_EMAIL_REGEX.test(String(this.email).toLowerCase());
		},
	},
	methods: {
		async onSignup() {
			if (!this.isEmailValid) {
				this.showError = true;
				return;
			}
			this.showError = false;
			this.loading = true;
			this.okToClose = false;

			try {
				await this.$store.dispatch('ui/applyForOnboardingCall', { email: this.email });
				this.$showMessage({
					type: 'success',
					title: this.$locale.baseText('onboardingCallSignupSucess.title'),
					message: this.$locale.baseText('onboardingCallSignupSucess.message'),
				});
				this.okToClose = true;
				this.modalBus.$emit('close');
			} catch (e) {
				this.$showError(
					e,
					this.$locale.baseText('onboardingCallSignupFailed.title'),
					this.$locale.baseText('onboardingCallSignupFailed.message'),
				);
				this.loading = false;
				this.okToClose = true;
			}
		},
		async onCancel() {
			this.okToClose = true;
			this.modalBus.$emit('close');
		},
		onModalClose() {
			return this.okToClose;
		},
	},
});
