


































import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import { mapGetters } from 'vuex';

import { IN8nPromptResponse } from '@/Interface';
import { VALID_EMAIL_REGEX } from '@/constants';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import Modal from './Modal.vue';

export default mixins(workflowHelpers).extend({
	components: { Modal },
	name: 'ContactPromptModal',
	props: ['modalName'],
	data() {
		return {
			email: '',
			modalBus: new Vue(),
		};
	},
	computed: {
		...mapGetters({
			promptsData: 'settings/getPromptsData',
		}),
		title(): string {
			if (this.promptsData && this.promptsData.title) {
				return this.promptsData.title;
			}

			return 'You’re a power user 💪';
		},
		description(): string {
			if (this.promptsData && this.promptsData.message) {
				return this.promptsData.message;
			}

			return 'Your experience with n8n can help us improve — for you and our entire community.';
		},
		isEmailValid(): boolean {
			return VALID_EMAIL_REGEX.test(String(this.email).toLowerCase());
		},
	},
	methods: {
		closeDialog(): void {
			if (!this.isEmailValid) {
				this.$telemetry.track('User closed email modal', {
					instance_id: this.$store.getters.instanceId,
					email: null,
				});
			}
		},
		async send() {
			if (this.isEmailValid) {
				const response: IN8nPromptResponse = await this.$store.dispatch(
					'settings/submitContactInfo',
					this.email,
				);

				if (response.updated) {
					this.$telemetry.track('User closed email modal', {
						instance_id: this.$store.getters.instanceId,
						email: this.email,
					});
					this.$showMessage({
						title: 'Thanks!',
						message: "It's people like you that help make n8n better",
						type: 'success',
					});
				}
				this.modalBus.$emit('close');
			}
		},
	},
});
