



























































































































































import { LOCAL_STORAGE_MAPPING_FLAG } from '@/constants';
import { INodeUi, ITableData } from '@/Interface';
import { GenericValue, IDataObject, INodeExecutionData } from 'n8n-workflow';
import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import Draggable from './Draggable.vue';
import { shorten } from './helpers';
import { externalHooks } from './mixins/externalHooks';

export default mixins(externalHooks).extend({
	name: 'RunDataTable',
	components: { Draggable },
	props: {
		node: {
			type: Object as () => INodeUi,
		},
		inputData: {
			type: Array as () => INodeExecutionData[],
		},
		mappingEnabled: {
			type: Boolean,
		},
		distanceFromActive: {
			type: Number,
		},
		showMappingHint: {
			type: Boolean,
		},
		runIndex: {
			type: Number,
		},
		totalRuns: {
			type: Number,
		},
	},
	data() {
		return {
			activeColumn: -1,
			showHintWithDelay: false,
			forceShowGrip: false,
			draggedColumn: false,
			draggingPath: null as null | string,
			hoveringPath: null as null | string,
			mappingHintVisible: false,
		};
	},
	mounted() {
		if (this.showMappingHint) {
			this.mappingHintVisible = true;

			setTimeout(() => {
				this.mappingHintVisible = false;
			}, 6000);
		}

		if (this.showMappingHint && this.showHint) {
			setTimeout(() => {
				this.showHintWithDelay = this.showHint;
				this.$telemetry.track('User viewed data mapping tooltip', { type: 'param focus' });
			}, 500);
		}

		if (this.tableData && this.tableData.columns && this.$refs.draggable) {
			const tbody = (this.$refs.draggable as Vue).$refs.wrapper as HTMLElement;
			if (tbody) {
				this.$emit('mounted', {
					avgRowHeight: tbody.offsetHeight / this.tableData.data.length,
				});
			}
		}
	},
	computed: {
		tableData(): ITableData {
			return this.convertToTable(this.inputData);
		},
		focusedMappableInput(): string {
			return this.$store.getters['ui/focusedMappableInput'];
		},
		showHint(): boolean {
			return (
				!this.draggedColumn &&
				((this.showMappingHint && this.mappingHintVisible) ||
					(!!this.focusedMappableInput &&
						window.localStorage.getItem(LOCAL_STORAGE_MAPPING_FLAG) !== 'true'))
			);
		},
	},
	methods: {
		shorten,
		onMouseEnterCell(e: MouseEvent) {
			const target = e.target;
			if (target && this.mappingEnabled) {
				const col = (target as HTMLElement).dataset.col;
				if (col && !isNaN(parseInt(col, 10))) {
					this.activeColumn = parseInt(col, 10);
				}
			}
		},
		onMouseLeaveCell() {
			this.activeColumn = -1;
		},
		onMouseEnterKey(path: string[], colIndex: number) {
			this.hoveringPath = this.getCellExpression(path, colIndex);
		},
		onMouseLeaveKey() {
			this.hoveringPath = null;
		},
		isHovering(path: string[], colIndex: number) {
			const expr = this.getCellExpression(path, colIndex);

			return this.hoveringPath === expr;
		},
		getExpression(column: string) {
			if (!this.node) {
				return '';
			}

			if (this.distanceFromActive === 1) {
				return `{{ $json["${column}"] }}`;
			}

			return `{{ $node["${this.node.name}"].json["${column}"] }}`;
		},
		getPathNameFromTarget(el: HTMLElement) {
			if (!el) {
				return '';
			}
			return el.dataset.name;
		},
		getCellPathName(path: Array<string | number>, colIndex: number) {
			const lastKey = path[path.length - 1];
			if (typeof lastKey === 'string') {
				return lastKey;
			}
			if (path.length > 1) {
				const prevKey = path[path.length - 2];
				return `${prevKey}[${lastKey}]`;
			}
			const column = this.tableData.columns[colIndex];
			return `${column}[${lastKey}]`;
		},
		getCellExpression(path: Array<string | number>, colIndex: number) {
			if (!this.node) {
				return '';
			}

			const expr = path.reduce((accu: string, key: string | number) => {
				if (typeof key === 'number') {
					return `${accu}[${key}]`;
				}

				return `${accu}["${key}"]`;
			}, '');
			const column = this.tableData.columns[colIndex];

			if (this.distanceFromActive === 1) {
				return `{{ $json["${column}"]${expr} }}`;
			}

			return `{{ $node["${this.node.name}"].json["${column}"]${expr} }}`;
		},
		isEmpty(value: unknown) {
			return (
				value === '' ||
				(Array.isArray(value) && value.length === 0) ||
				(typeof value === 'object' && value !== null && Object.keys(value).length === 0)
			);
		},
		getValueToRender(value: unknown) {
			if (value === '') {
				return this.$locale.baseText('runData.emptyString');
			}
			if (typeof value === 'string') {
				return value.replaceAll('\n', '\\n');
			}

			if (Array.isArray(value) && value.length === 0) {
				return this.$locale.baseText('runData.emptyArray');
			}

			if (typeof value === 'object' && value !== null && Object.keys(value).length === 0) {
				return this.$locale.baseText('runData.emptyObject');
			}

			return value;
		},
		onDragStart() {
			this.draggedColumn = true;

			this.$store.commit('ui/resetMappingTelemetry');
		},
		onCellDragStart(el: HTMLElement) {
			if (el && el.dataset.value) {
				this.draggingPath = el.dataset.value;
			}

			this.onDragStart();
		},
		onCellDragEnd(el: HTMLElement) {
			this.draggingPath = null;

			this.onDragEnd(el.dataset.name || '', 'tree', el.dataset.depth || '0');
		},
		isDraggingKey(path: Array<string | number>, colIndex: number) {
			if (!this.draggingPath) {
				return;
			}

			return this.draggingPath === this.getCellExpression(path, colIndex);
		},
		onDragEnd(column: string, src: string, depth = '0') {
			setTimeout(() => {
				const mappingTelemetry = this.$store.getters['ui/mappingTelemetry'];
				const telemetryPayload = {
					src_node_type: this.node.type,
					src_field_name: column,
					src_nodes_back: this.distanceFromActive,
					src_run_index: this.runIndex,
					src_runs_total: this.totalRuns,
					src_field_nest_level: parseInt(depth, 10),
					src_view: 'table',
					src_element: src,
					success: false,
					...mappingTelemetry,
				};

				this.$externalHooks().run('runDataTable.onDragEnd', telemetryPayload);

				this.$telemetry.track('User dragged data for mapping', telemetryPayload);
			}, 1000); // ensure dest data gets set if drop
		},
		isSimple(data: unknown): boolean {
			return typeof data !== 'object';
		},
		hasJsonInColumn(colIndex: number): boolean {
			return this.tableData.hasJson[this.tableData.columns[colIndex]];
		},
		convertToTable(inputData: INodeExecutionData[]): ITableData {
			const tableData: GenericValue[][] = [];
			const tableColumns: string[] = [];
			let leftEntryColumns: string[], entryRows: GenericValue[];
			// Go over all entries
			let entry: IDataObject;
			const hasJson: { [key: string]: boolean } = {};
			inputData.forEach((data) => {
				if (!data.hasOwnProperty('json')) {
					return;
				}
				entry = data.json;

				// Go over all keys of entry
				entryRows = [];
				leftEntryColumns = Object.keys(entry || {});

				// Go over all the already existing column-keys
				tableColumns.forEach((key) => {
					if (entry.hasOwnProperty(key)) {
						// Entry does have key so add its value
						entryRows.push(entry[key]);
						// Remove key so that we know that it got added
						leftEntryColumns.splice(leftEntryColumns.indexOf(key), 1);

						hasJson[key] =
							hasJson[key] ||
							(typeof entry[key] === 'object' && Object.keys(entry[key] || {}).length > 0) ||
							false;
					} else {
						// Entry does not have key so add null
						entryRows.push(null);
					}
				});

				// Go over all the columns the entry has but did not exist yet
				leftEntryColumns.forEach((key) => {
					// Add the key for all runs in the future
					tableColumns.push(key);
					// Add the value
					entryRows.push(entry[key]);
					hasJson[key] =
						hasJson[key] ||
						(typeof entry[key] === 'object' && Object.keys(entry[key] || {}).length > 0) ||
						false;
				});

				// Add the data of the entry
				tableData.push(entryRows);
			});

			// Make sure that all entry-rows have the same length
			tableData.forEach((entryRows) => {
				if (tableColumns.length > entryRows.length) {
					// Has to less entries so add the missing ones
					entryRows.push.apply(entryRows, new Array(tableColumns.length - entryRows.length));
				}
			});

			return {
				hasJson,
				columns: tableColumns,
				data: tableData,
			};
		},
	},
	watch: {
		focusedMappableInput(curr: boolean) {
			setTimeout(
				() => {
					this.forceShowGrip = !!this.focusedMappableInput;
				},
				curr ? 300 : 150,
			);
		},
		showHint(curr: boolean, prev: boolean) {
			if (curr) {
				setTimeout(() => {
					this.showHintWithDelay = this.showHint;
					if (this.showHintWithDelay) {
						this.$telemetry.track('User viewed data mapping tooltip', { type: 'param focus' });
					}
				}, 1000);
			} else {
				this.showHintWithDelay = false;
			}
		},
	},
});
