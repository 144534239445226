










































import {
	INodeTypeDescription,
	IWebhookDescription,
} from 'n8n-workflow';

import { WEBHOOK_NODE_TYPE } from '@/constants';
import { copyPaste } from '@/components/mixins/copyPaste';
import { showMessage } from '@/components/mixins/showMessage';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

import mixins from 'vue-typed-mixins';

export default mixins(
	copyPaste,
	showMessage,
	workflowHelpers,
)
	.extend({
		name: 'NodeWebhooks',
		props: [
			'node', // NodeUi
			'nodeType', // INodeTypeDescription
		],
		data () {
			return {
				isMinimized: this.nodeType && this.nodeType.name !== WEBHOOK_NODE_TYPE,
				showUrlFor: 'test',
			};
		},
		computed: {
			webhooksNode (): IWebhookDescription[] {
				if (this.nodeType === null || this.nodeType.webhooks === undefined) {
					return [];
				}

				return (this.nodeType as INodeTypeDescription).webhooks!.filter(webhookData => webhookData.restartWebhook !== true);
			},
		},
		methods: {
			copyWebhookUrl (webhookData: IWebhookDescription): void {
				const webhookUrl = this.getWebhookUrlDisplay(webhookData);
				this.copyToClipboard(webhookUrl);

				this.$showMessage({
					title: this.$locale.baseText('nodeWebhooks.showMessage.title'),
					type: 'success',
				});
				this.$telemetry.track('User copied webhook URL', {
					pane: 'parameters',
					type: `${this.showUrlFor} url`,
				});
			},
			getWebhookUrlDisplay (webhookData: IWebhookDescription): string {
				if (this.node) {
					return this.getWebhookUrl(webhookData, this.node, this.showUrlFor);
				}
				return '';
			},
		},
		watch: {
			node () {
				this.isMinimized = this.nodeType.name !== WEBHOOK_NODE_TYPE;
			},
		},
	});
