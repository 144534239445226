







































import { genericHelpers } from '@/components/mixins/genericHelpers';
import mixins from 'vue-typed-mixins';
import { filterTemplateNodes, abbreviateNumber } from './helpers';
import NodeList from './NodeList.vue';

export default mixins(genericHelpers).extend({
	name: 'TemplateCard',
	props: {
		lastItem: {
			type: Boolean,
			default: false,
		},
		firstItem: {
			type: Boolean,
			default: false,
		},
		workflow: {
			type: Object,
		},
		useWorkflowButton: {
			type: Boolean,
		},
		loading: {
			type: Boolean,
		},
	},
	components: {
		NodeList,
	},
	data() {
		return {
			nodesToBeShown: 5,
		};
	},
	methods: {
		filterTemplateNodes,
		abbreviateNumber,
		countNodesToBeSliced(nodes: []): number {
			if (nodes.length > this.nodesToBeShown) {
				return this.nodesToBeShown - 1;
			} else {
				return this.nodesToBeShown;
			}
		},
		onUseWorkflowClick(e: MouseEvent) {
			this.$emit('useWorkflow', e);
		},
		onCardClick(e: MouseEvent) {
			this.$emit('click', e);
		},
	},
});
