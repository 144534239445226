











































import Vue from 'vue';
import NodeIcon from './NodeIcon.vue';
import TimeAgo from './TimeAgo.vue';
import Badge from './Badge.vue';
import WarningTooltip from './WarningTooltip.vue';
import { IVersionNode } from '@/Interface';

Vue.component('NodeIcon', NodeIcon);
Vue.component('TimeAgo', TimeAgo);
Vue.component('Badge', Badge);
Vue.component('WarningTooltip', WarningTooltip);

export default Vue.extend({
	components: { NodeIcon, TimeAgo, Badge, WarningTooltip },
	name: 'VersionCard',
	props: ['version'],
	// @ts-ignore
	nodeName (node: IVersionNode): string {
		return node !== null ? node.displayName : this.$locale.baseText('versionCard.unknown');
	},
});
