

















import Vue from 'vue';
import GoBackButton from '@/components/GoBackButton.vue';

export default Vue.extend({
	name: 'TemplatesView',
	components: {
		GoBackButton,
	},
	props: {
		goBackEnabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		isMenuCollapsed(): boolean {
			return this.$store.getters['ui/sidebarMenuCollapsed'];
		},
	},
});
