



































import Vue from 'vue';
import { mapGetters } from 'vuex';
import {IUser} from "@/Interface";
import mixins from "vue-typed-mixins";
import {showMessage} from "@/components/mixins/showMessage";

export default mixins(
	showMessage,
).extend({
	name: 'CredentialSharing',
	props: ['credential', 'credentialId', 'credentialData', 'sharedWith', 'credentialPermissions'],
	computed: {
		...mapGetters('users', ['allUsers', 'currentUser']),
		usersList(): IUser[] {
			return this.allUsers.filter((user: IUser) => {
				const isCurrentUser = user.id === this.currentUser.id;
				const isAlreadySharedWithUser = (this.credentialData.sharedWith || []).find((sharee: IUser) => sharee.id === user.id);

				return !isCurrentUser && !isAlreadySharedWithUser;
			});
		},
		sharedWithList(): IUser[] {
			return [
				{
					...(this.credential ? this.credential.ownedBy : this.currentUser),
					isOwner: true,
				},
			].concat(this.credentialData.sharedWith || []);
		},
		credentialOwnerName(): string {
			return this.$store.getters['credentials/getCredentialOwnerName'](this.credentialId);
		},
	},
	methods: {
		async onAddSharee(userId: string) {
			const { id, firstName, lastName, email } = this.$store.getters['users/getUserById'](userId);
			const sharee = { id, firstName, lastName, email };

			this.$emit('change', (this.credentialData.sharedWith || []).concat(sharee));
		},
		async onRemoveSharee(userId: string) {
			const user = this.$store.getters['users/getUserById'](userId);

			const confirm = await this.confirmMessage(
				this.$locale.baseText('credentialEdit.credentialSharing.list.delete.confirm.message', { interpolate: { name: user.fullName } }),
				this.$locale.baseText('credentialEdit.credentialSharing.list.delete.confirm.title'),
				null,
				this.$locale.baseText('credentialEdit.credentialSharing.list.delete.confirm.confirmButtonText'),
				this.$locale.baseText('credentialEdit.credentialSharing.list.delete.confirm.cancelButtonText'),
			);

			if (confirm) {
				this.$emit('change', this.credentialData.sharedWith.filter((sharee: IUser) => {
					return sharee.id !== user.id;
				}));
			}
		},
		async loadUsers() {
			await this.$store.dispatch('users/fetchUsers');
		},
	},
	mounted() {
		this.loadUsers();
	},
});
