

































import {
	INodeUi,
	IUpdateInformation,
} from '@/Interface';

import {
	INodeProperties,
	INodePropertyOptions,
} from 'n8n-workflow';

import { genericHelpers } from '@/components/mixins/genericHelpers';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';

import { get } from 'lodash';

import mixins from 'vue-typed-mixins';

export default mixins(
	genericHelpers,
	nodeHelpers,
)
	.extend({
		name: 'CollectionParameter',
		props: [
			'hideDelete', // boolean
			'nodeValues', // NodeParameters
			'parameter', // INodeProperties
			'path', // string
			'values', // NodeParameters
		],
		data () {
			return {
				selectedOption: undefined,
			};
		},
		computed: {
			getPlaceholderText (): string {
				const placeholder = this.$locale.nodeText().placeholder(this.parameter, this.path);
				return placeholder ? placeholder : this.$locale.baseText('collectionParameter.choose');
			},
			getProperties (): INodeProperties[] {
				const returnProperties = [];
				let tempProperties;
				for (const name of this.propertyNames) {
					tempProperties = this.getOptionProperties(name);
					if (tempProperties !== undefined) {
						returnProperties.push(...tempProperties);
					}
				}
				return returnProperties;
			},
			// Returns all the options which should be displayed
			filteredOptions (): Array<INodePropertyOptions | INodeProperties> {
				return (this.parameter.options as Array<INodePropertyOptions | INodeProperties>).filter((option) => {
					return this.displayNodeParameter(option as INodeProperties);
				});
			},
			node (): INodeUi {
				return this.$store.getters.activeNode;
			},
			// Returns all the options which did not get added already
			parameterOptions (): Array<INodePropertyOptions | INodeProperties> {
				return (this.filteredOptions as Array<INodePropertyOptions | INodeProperties>).filter((option) => {
					return !this.propertyNames.includes(option.name);
				});
			},
			propertyNames (): string[] {
				if (this.values) {
					return Object.keys(this.values);
				}
				return [];
			},
		},
		methods: {
			getArgument (argumentName: string): string | number | boolean | undefined {
				if (this.parameter.typeOptions === undefined) {
					return undefined;
				}

				if (this.parameter.typeOptions[argumentName] === undefined) {
					return undefined;
				}

				return this.parameter.typeOptions[argumentName];
			},
			getOptionProperties (optionName: string): INodeProperties[] {
				const properties: INodeProperties[] = [];
				for (const option of this.parameter.options) {
					if (option.name === optionName) {
						properties.push(option);
					}
				}

				return properties;
			},
			displayNodeParameter (parameter: INodeProperties) {
				if (parameter.displayOptions === undefined) {
					// If it is not defined no need to do a proper check
					return true;
				}
				return this.displayParameter(this.nodeValues, parameter, this.path, this.node);
			},
			optionSelected (optionName: string) {
				const options = this.getOptionProperties(optionName);
				if (options.length === 0) {
					return;
				}

				const option = options[0];
				const name = `${this.path}.${option.name}`;

				let parameterData;

				if (option.typeOptions !== undefined && option.typeOptions.multipleValues === true) {
					// Multiple values are allowed

					let newValue;
					if (option.type === 'fixedCollection') {
						// The "fixedCollection" entries are different as they save values
						// in an object and then underneath there is an array. So initialize
						// them differently.
						newValue = get(this.nodeValues, `${this.path}.${optionName}`, {});
					} else {
						// Everything else saves them directly as an array.
						newValue = get(this.nodeValues, `${this.path}.${optionName}`, []);
						newValue.push(JSON.parse(JSON.stringify(option.default)));
					}

					parameterData = {
						name,
						value: newValue,
					};
				} else {
					// Add a new option
					parameterData = {
						name,
						value: JSON.parse(JSON.stringify(option.default)),
					};
				}

				this.$emit('valueChanged', parameterData);
				this.selectedOption = undefined;
			},
			valueChanged (parameterData: IUpdateInformation) {
				this.$emit('valueChanged', parameterData);
			},
		},
		beforeCreate: function () { // tslint:disable-line
			// Because we have a circular dependency on ParameterInputList import it here
			// to not break Vue.
			this.$options!.components!.ParameterInputList = require('./ParameterInputList.vue').default;
		},
	});
