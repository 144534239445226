















import { IVersionNode } from '@/Interface';
import { INodeTypeDescription } from 'n8n-workflow';
import Vue from 'vue';

interface NodeIconSource {
		path?: string;
		fileBuffer?: string;
		icon?: string;
}

export default Vue.extend({
	name: 'NodeIcon',
	props: {
		nodeType: {
		},
		size: {
			type: Number,
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		circle: {
			type: Boolean,
			default: false,
		},
		showTooltip: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		type (): string {
			const nodeType = this.nodeType as INodeTypeDescription | IVersionNode | null;
			let iconType = 'unknown';
			if (nodeType) {
				if ((nodeType as IVersionNode).iconData) {
					iconType = (nodeType as IVersionNode).iconData.type;
				} else if (nodeType.icon) {
					iconType = nodeType.icon.split(':')[0] === 'file' ? 'file' : 'icon';
				}
			}
			return iconType;
		},
		color () : string {
			const nodeType = this.nodeType as INodeTypeDescription | IVersionNode | null;
			if (nodeType && nodeType.defaults && nodeType.defaults.color) {
				return nodeType.defaults.color.toString();
			}
			return '';
		},
		iconSource () : NodeIconSource {
			const nodeType = this.nodeType as INodeTypeDescription | IVersionNode | null;
			const restUrl = this.$store.getters.getRestUrl;
			const iconSource = {} as NodeIconSource;

			if (nodeType) {
				// If node type has icon data, use it
				if ((nodeType as IVersionNode).iconData) {
					return {
						icon: (nodeType as IVersionNode).iconData.icon,
						fileBuffer: (nodeType as IVersionNode).iconData.fileBuffer,
					};
				}
				// Otherwise, extract it from icon prop
				if (nodeType.icon) {
					let type, path;
					[type, path] = nodeType.icon.split(':');
					if (type === 'file') {
						iconSource.path = `${restUrl}/node-icon/${nodeType.name}`;
					} else {
						iconSource.icon = path;
					}
				}
			}
			return iconSource;
		},
	},
});
