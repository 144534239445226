



















































































































































































































































import {showMessage} from '@/components/mixins/showMessage';
import {ICredentialsResponse, IUser} from '@/Interface';
import mixins from 'vue-typed-mixins';

import SettingsView from './SettingsView.vue';
import PageViewLayout from "@/components/layouts/PageViewLayout.vue";
import PageViewLayoutList from "@/components/layouts/PageViewLayoutList.vue";
import CredentialCard from "@/components/CredentialCard.vue";
import {CREDENTIAL_SELECT_MODAL_KEY} from "@/constants";
import {ICredentialType} from "n8n-workflow";
import {EnterpriseEditionFeature} from "@/constants";
import TemplateCard from "@/components/TemplateCard.vue";
import Vue from "vue";
import { debounceHelper } from '@/components/mixins/debounce';

export default mixins(
	showMessage,
	debounceHelper,
).extend({
	name: 'SettingsPersonalView',
	components: {
		TemplateCard,
		PageViewLayout,
		PageViewLayoutList,
		SettingsView,
		CredentialCard,
	},
	data() {
		return {
			loading: true,
			filters: {
				owner: true,
				sortBy: 'lastUpdated',
				search: '',
				type: [] as string[],
				ownedBy: '',
				sharedWith: '',
			},
			filtersInput: {
				type: [] as string[],
				ownedBy: '',
				sharedWith: '',
			},
			resettingFilters: false,
			EnterpriseEditionFeature,
		};
	},
	computed: {
		currentUser(): IUser {
			return this.$store.getters['users/currentUser'];
		},
		allUsers(): IUser[] {
			return this.$store.getters['users/allUsers'];
		},
		allCredentials(): ICredentialsResponse[] {
			return this.$store.getters['credentials/allCredentials'];
		},
		allCredentialTypes(): ICredentialType[] {
			return this.$store.getters['credentials/allCredentialTypes'];
		},
		credentialTypesById(): Record<ICredentialType['name'], ICredentialType> {
			return this.$store.getters['credentials/credentialTypesById'];
		},
		filtersLength(): number {
			let length = 0;

			if (this.filters.ownedBy) {
				length += 1;
			}
			if (this.filters.sharedWith) {
				length += 1;
			}
			if (this.filters.type.length > 0) {
				length += 1;
			}

			return length;
		},
		subviewCredentials(): ICredentialsResponse[] {
			return this.allCredentials.filter((credential: ICredentialsResponse) => {
				if (this.filters.owner && this.$store.getters['settings/isEnterpriseFeatureEnabled'](EnterpriseEditionFeature.Sharing)) {
					return !!(credential.ownedBy && credential.ownedBy.id === this.currentUser.id);
				}

				return true;
			});
		},
		filteredAndSortedSubviewCredentials(): ICredentialsResponse[] {
			const filtered: ICredentialsResponse[] = this.subviewCredentials.filter((credential: ICredentialsResponse) => {
				let matches = true;

				if (this.filters.ownedBy) {
					matches = matches && !!(credential.ownedBy && credential.ownedBy.id === this.filters.ownedBy);
				}

				if (this.filters.sharedWith) {
					matches = matches && !!(credential.sharedWith && credential.sharedWith.find((sharee) => sharee.id === this.filters.sharedWith));
				}

				if (this.filters.type.length > 0) {
					matches = matches && this.filters.type.includes(credential.type);
				}

				if (this.filters.search) {
					const searchString = this.filters.search.toLowerCase();

					matches = matches && (
						credential.name.toLowerCase().includes(searchString) ||
						this.credentialTypesById[credential.type] && this.credentialTypesById[credential.type].displayName.toLowerCase().includes(searchString)
					);
				}

				return matches;
			});

			return filtered.sort((a, b) => {
				switch (this.filters.sortBy) {
					case 'lastUpdated':
						return (new Date(b.updatedAt)).valueOf() - (new Date(a.updatedAt)).valueOf();
					case 'lastCreated':
						return (new Date(b.createdAt)).valueOf() - (new Date(a.createdAt)).valueOf();
					case 'nameAsc':
						return a.name.localeCompare(b.name);
					case 'nameDesc':
						return b.name.localeCompare(a.name);
					default:
						return 0;
				}
			});
		},
		credentialsNotOwned(): ICredentialsResponse[] {
			return this.allCredentials.filter((credential: ICredentialsResponse) => {
				return credential.ownedBy && credential.ownedBy.id !== this.currentUser.id;
			});
		},
		hasFilters(): boolean {
			return this.filters.type.length > 0 ||
				this.filters.ownedBy !== '' ||
				this.filters.sharedWith !== '';
		},
	},
	methods: {
		addCredential() {
			this.$store.dispatch('ui/openModal', CREDENTIAL_SELECT_MODAL_KEY);
			this.resetFilters();

			this.$telemetry.track('User clicked add cred button', {
				source: 'Creds list',
			});
		},
		async initialize() {
			await Promise.all([
				this.$store.dispatch('credentials/fetchAllCredentials'),
				this.$store.dispatch('credentials/fetchCredentialTypes'),
				this.$store.dispatch('nodeTypes/getNodeTypes'),
			]);

			this.loading = false;
			this.$nextTick(this.focusSearchInput);

			this.$store.dispatch('users/fetchUsers'); // Can be loaded in the background, used for filtering
		},
		resetFilters() {
			this.filters.search = '';
			this.filters.type = [];
			this.filters.ownedBy = '';
			this.filters.sharedWith = '';
			this.filtersInput.type = [];
			this.filtersInput.ownedBy = '';
			this.filtersInput.sharedWith = '';

			this.resettingFilters = true;
			this.sendFiltersTelemetry('reset');
		},
		focusSearchInput() {
			if (this.$refs.search) {
				(this.$refs.search as Vue & { focus: () => void }).focus();
			}
		},
		setOwnerFilter(active: boolean) {
			(this.$refs.selectOwnerMenu as Vue & { $children: Array<{ activeIndex: string; }> }).$children[0].activeIndex = active ? 'owner' : 'all';
			this.filters.owner = active;
		},
		onSelectOwner(type: string) {
			this.filters.owner = type === 'owner';
		},
		sendSubviewTelemetry() {
			this.$telemetry.track('User changed credentials sub view', {
				sub_view: this.filters.owner ? 'My credentials' : 'All credentials',
			});
		},
		sendSortingTelemetry() {
			this.$telemetry.track('User changed sorting in cred list', {
				sub_view: this.filters.owner ? 'My credentials' : 'All credentials',
				sorting: this.filters.sortBy,
			});
		},
		sendFiltersTelemetry(source: string) {
			// Prevent sending multiple telemetry events when resetting filters
			// Timeout is required to wait for search debounce to be over
			if (this.resettingFilters) {
				if (source !== 'reset') {
					return;
				}

				setTimeout(() => this.resettingFilters = false, 1500);
			}

			const filters = this.filters as Record<string, string[] | string | boolean>;
			const filtersSet: string[] = [];
			const filterValues: Array<string[] | string | boolean | null> = [];

			['ownedBy', 'sharedWith', 'type', 'search'].forEach((key) => {
				if (filters[key]) {
					filtersSet.push(key);
					filterValues.push(key === 'search' ? null : filters[key]);
				}
			});

			this.$telemetry.track('User set filters in cred list', {
				filters_set: filtersSet,
				filter_values: filterValues,
				sub_view: this.filters.owner ? 'My credentials' : 'All credentials',
				creds_total_in_view: this.subviewCredentials.length,
				creds_after_filtering: this.filteredAndSortedSubviewCredentials.length,
			});
		},
	},
	mounted() {
		this.initialize();
	},
	watch: {
		'filters.owner'() {
			this.sendSubviewTelemetry();
		},
		'filters.ownedBy'(value) {
			if (value) {
				this.setOwnerFilter(false);
			}
			this.sendFiltersTelemetry('ownedBy');
		},
		'filters.sharedWith'() {
			this.sendFiltersTelemetry('sharedWith');
		},
		'filters.type'() {
			this.sendFiltersTelemetry('type');
		},
		'filters.search'() {
			this.callDebounced('sendFiltersTelemetry', { debounceTime: 1000, trailing: true }, 'search');
		},
		'filters.sortBy'() {
			this.sendSortingTelemetry();
		},
	},
});
