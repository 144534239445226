






















































import mixins from "vue-typed-mixins";
import { mapGetters } from "vuex";

import { ITag } from "@/Interface";
import { MAX_TAG_NAME_LENGTH, TAGS_MANAGER_MODAL_KEY } from "@/constants";

import { showMessage } from "@/components/mixins/showMessage";

const MANAGE_KEY = "__manage";
const CREATE_KEY = "__create";

export default mixins(showMessage).extend({
	name: "TagsDropdown",
	props: ["placeholder", "currentTagIds", "createEnabled", "eventBus"],
	data() {
		return {
			filter: "",
			MANAGE_KEY,
			CREATE_KEY,
			focused: false,
			preventUpdate: false,
		};
	},
	mounted() {
		// @ts-ignore
		const select = (this.$refs.select && this.$refs.select.$refs && this.$refs.select.$refs.innerSelect) as (Vue | undefined);
		if (select) {
			const input = select.$refs.input as (Element | undefined);
			if (input) {
				input.setAttribute('maxlength', `${MAX_TAG_NAME_LENGTH}`);
				input.addEventListener('keydown', (e: Event) => {
					const keyboardEvent = e as KeyboardEvent;
					// events don't bubble outside of select, so need to hook onto input
					if (keyboardEvent.key === 'Escape') {
						this.$emit('esc');
					}
					else if (keyboardEvent.key === 'Enter' && this.filter.length === 0) {
						this.$data.preventUpdate = true;
						this.$emit('blur');

						// @ts-ignore
						if (this.$refs.select && typeof this.$refs.select.blur === 'function') {
							// @ts-ignore
							this.$refs.select.blur();
						}
					}
				});
			}
		}

		if (this.$props.eventBus) {
			this.$props.eventBus.$on('focus', () => {
				this.focusOnInput();
				this.focusOnTopOption();
			});
		}

		this.$store.dispatch("tags/fetchAll");
	},
	computed: {
		...mapGetters("tags", ["allTags", "isLoading", "hasTags"]),
		options(): ITag[] {
			return this.allTags
				.filter((tag: ITag) =>
					tag && tag.name.toLowerCase().includes(this.$data.filter.toLowerCase()),
				);
		},
		appliedTags(): string[] {
			return this.$props.currentTagIds.filter((id: string) =>
				this.$store.getters['tags/getTagById'](id),
			);
		},
	},
	methods: {
		filterOptions(filter = "") {
			this.$data.filter = filter.trim();
			this.$nextTick(() => this.focusOnTopOption());
		},
		async onCreate() {
			const name = this.$data.filter;
			try {
				const newTag = await this.$store.dispatch("tags/create", name);
				this.$emit("update", [...this.$props.currentTagIds, newTag.id]);
				this.$nextTick(() => this.focusOnTag(newTag.id));

				this.$data.filter = "";
			} catch (error) {
				this.$showError(
					error,
					this.$locale.baseText('tagsDropdown.showError.title'),
					this.$locale.baseText(
						'tagsDropdown.showError.message',
						{ interpolate: { name } },
					),
				);
			}
		},
		onTagsUpdated(selected: string[]) {
			const ops = selected.find(
				(value) => value === MANAGE_KEY || value === CREATE_KEY,
			);
			if (ops === MANAGE_KEY) {
				this.$data.filter = "";
				this.$store.dispatch("ui/openModal", TAGS_MANAGER_MODAL_KEY);
			} else if (ops === CREATE_KEY) {
				this.onCreate();
			} else {
				setTimeout(() => {
					if (!this.$data.preventUpdate) {
						this.$emit("update", selected);
					}
					this.$data.preventUpdate = false;
				}, 0);
			}
		},
		focusOnTopOption() {
			const tags = this.$refs.tag as Vue[] | undefined;
			const create = this.$refs.create as Vue | undefined;
			//@ts-ignore // focus on create option
			if (create && create.hoverItem) {
				// @ts-ignore
				create.hoverItem();
			}
			//@ts-ignore // focus on top option after filter
			else if (tags && tags[0] && tags[0].hoverItem) {
				// @ts-ignore
				tags[0].hoverItem();

				// @ts-ignore
				if (tags[0] && tags[0].$el && tags[0].$el.scrollIntoView) {
					// @ts-ignore
					tags[0].$el.scrollIntoView();
				}
			}
		},
		focusOnTag(tagId: string) {
			const tagOptions = (this.$refs.tag as Vue[]) || [];
			if (tagOptions && tagOptions.length) {
				const added = tagOptions.find((ref: any) => ref.value === tagId); // tslint:disable-line:no-any
				// @ts-ignore // focus on newly created item
				if (added && added.$el && added.$el.scrollIntoView && added.hoverItem) {
					// @ts-ignore
					added.hoverItem();
					added.$el.scrollIntoView();
				}
			}
		},
		focusOnInput() {
			const select = (this.$refs.select) as (Vue | undefined);
			if (select) {
				// @ts-ignore
				select.focusOnInput();
				this.focused = true;
			}
		},
		onVisibleChange(visible: boolean) {
			if (!visible) {
				this.$data.filter = '';
				this.focused = false;
			}
			else {
				this.focused = true;
			}
		},
		onRemoveTag() {
			this.$nextTick(() => {
				this.focusOnInput();
			});
		},
		onClickOutside(e: Event) {
			if (e.type === 'click') {
				this.$emit('blur');
			}
		},
	},
	watch: {
		allTags() {
			// keep applied tags in sync with store
			// for example in case tag is deleted from store
			if (this.currentTagIds.length !== this.appliedTags.length) {
				this.$emit("update", this.appliedTags);
			}
		},
	},
});
