import { render, staticRenderFns } from "./InputPanel.vue?vue&type=template&id=151c3b5c&scoped=true&"
import script from "./InputPanel.vue?vue&type=script&lang=ts&"
export * from "./InputPanel.vue?vue&type=script&lang=ts&"
import style0 from "./InputPanel.vue?vue&type=style&index=0&id=151c3b5c&prod&lang=scss&module=true&"
import style1 from "./InputPanel.vue?vue&type=style&index=1&id=151c3b5c&prod&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "151c3b5c",
  null
  
)

export default component.exports