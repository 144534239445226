


















































import Vue from 'vue';
import { mapGetters } from "vuex";
import mixins from 'vue-typed-mixins';

import Modal from './Modal.vue';
import { CREDENTIAL_SELECT_MODAL_KEY } from '../constants';
import { externalHooks } from '@/components/mixins/externalHooks';

export default mixins(externalHooks).extend({
	name: 'CredentialsSelectModal',
	components: {
		Modal,
	},
	async mounted() {
		try {
			await this.$store.dispatch('credentials/fetchCredentialTypes');
		} catch (e) {
		}
		this.loading = false;

		setTimeout(() => {
			const element = this.$refs.select as HTMLSelectElement;
			if (element) {
				element.focus();
			}
		}, 0);
	},
	data() {
		return {
			modalBus: new Vue(),
			selected: '',
			loading: true,
			CREDENTIAL_SELECT_MODAL_KEY,
		};
	},
	computed: {
		...mapGetters('credentials', ['allCredentialTypes']),
	},
	methods: {
		onSelect(type: string) {
			this.selected = type;
		},
		openCredentialType () {
			this.modalBus.$emit('close');
			this.$store.dispatch('ui/openNewCredential', { type: this.selected });

			const telemetryPayload = {
				credential_type: this.selected,
				source: 'primary_menu',
				new_credential: true,
				workflow_id: this.$store.getters.workflowId,
			};

			this.$telemetry.track('User opened Credential modal', telemetryPayload);
			this.$externalHooks().run('credentialsSelectModal.openCredentialType', telemetryPayload);
		},
	},
});
