







import Vue from "vue";

export default Vue.extend({
	name: "ExpandableInputBase",
	props: ['value', 'placeholder', 'staticSize'],
	computed: {
		hiddenValue() {
			let value = (this.value as string).replace(/\s/g, '.'); // force input to expand on space chars
			if (!value) {
				// @ts-ignore
				value = this.$props.placeholder;
			}

			return `${value}`;  // adjust for padding
		},
	},
});
