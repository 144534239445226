



























































































//@ts-ignore
import VueJsonPretty from 'vue-json-pretty';
import { copyPaste } from '@/components/mixins/copyPaste';
import { showMessage } from '@/components/mixins/showMessage';
import mixins from 'vue-typed-mixins';
import {
	MAX_DISPLAY_DATA_SIZE,
} from '@/constants';
import {
	INodeUi,
} from '@/Interface';

import {
	INodeProperties,
	INodePropertyCollection,
	INodePropertyOptions,
	INodeTypeDescription,
} from 'n8n-workflow';

export default mixins(
	copyPaste,
	showMessage,
).extend({
	name: 'NodeErrorView',
	props: [
		'error',
	],
	components: {
		VueJsonPretty,
	},
	computed: {
		displayCause(): boolean {
			return JSON.stringify(this.error.cause).length < MAX_DISPLAY_DATA_SIZE;
		},
		parameters (): INodeProperties[] {
			const node = this.$store.getters.activeNode;
			if (!node) {
				return [];
			}
			const nodeType = this.$store.getters['nodeTypes/getNodeType'](node.type, node.typeVersion);

			if (nodeType === null) {
				return [];
			}

			return nodeType.properties;
		},
	},
	methods: {
		getErrorDescription (): string {
			if (!this.error.context || !this.error.context.descriptionTemplate) {
				return this.error.description;
			}

			const parameterName = this.parameterDisplayName(this.error.context.parameter);
			return this.error.context.descriptionTemplate.replace(/%%PARAMETER%%/g, parameterName);
		},
		getErrorMessage (): string {
			if (!this.error.context || !this.error.context.messageTemplate) {
				return this.error.message;
			}

			const parameterName = this.parameterDisplayName(this.error.context.parameter);
			return this.error.context.messageTemplate.replace(/%%PARAMETER%%/g, parameterName);
		},
		parameterDisplayName(path: string) {
			try {
				const parameters = this.parameterName(this.parameters, path.split('.'));
				if (!parameters.length) {
					throw new Error();
				}
				return parameters.map(parameter => parameter.displayName).join(' > ');
			} catch (error) {
				return `Could not find parameter "${path}"`;
			}
		},
		parameterName(parameters: Array<(INodePropertyOptions | INodeProperties | INodePropertyCollection)>, pathParts: string[]): Array<(INodeProperties | INodePropertyCollection)> {
			let currentParameterName = pathParts.shift();

			if (currentParameterName === undefined) {
				return [];
			}

			const arrayMatch = currentParameterName.match(/(.*)\[([\d])\]$/);
			if (arrayMatch !== null && arrayMatch.length > 0) {
				currentParameterName = arrayMatch[1];
			}
			const currentParameter = parameters.find(parameter => parameter.name === currentParameterName) as unknown as INodeProperties | INodePropertyCollection;

			if (currentParameter === undefined) {
				throw new Error(`Could not find parameter "${currentParameterName}"`);
			}

			if (pathParts.length === 0) {
				return [currentParameter];
			}

			if (currentParameter.hasOwnProperty('options')) {
				return [currentParameter, ...this.parameterName((currentParameter as INodeProperties).options!, pathParts)];
			}

			if (currentParameter.hasOwnProperty('values')) {
				return [currentParameter, ...this.parameterName((currentParameter as INodePropertyCollection).values, pathParts)];
			}

			// We can not resolve any deeper so lets stop here and at least return hopefully something useful
			return [currentParameter];
		},
		copyCause() {
			this.copyToClipboard(JSON.stringify(this.error.cause));
			this.copySuccess();
		},
		copySuccess() {
			this.$showMessage({
				title: this.$locale.baseText('nodeErrorView.showMessage.title'),
				type: 'info',
			});
		},
	},
});
