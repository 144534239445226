var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalRoot',{attrs:{"name":_vm.CONTACT_PROMPT_MODAL_KEY},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var modalName = ref.modalName;
return [_c('ContactPromptModal',{attrs:{"modalName":modalName}})]}}])}),_c('ModalRoot',{attrs:{"name":_vm.CREDENTIAL_EDIT_MODAL_KEY},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var modalName = ref.modalName;
var activeId = ref.activeId;
var mode = ref.mode;
return [_c('CredentialEdit',{attrs:{"modalName":modalName,"mode":mode,"activeId":activeId}})]}}])}),_c('ModalRoot',{attrs:{"name":_vm.ABOUT_MODAL_KEY}},[_c('AboutModal')],1),_c('ModalRoot',{attrs:{"name":_vm.CREDENTIAL_SELECT_MODAL_KEY}},[_c('CredentialsSelectModal')],1),_c('ModalRoot',{attrs:{"name":_vm.CREDENTIAL_LIST_MODAL_KEY}},[_c('CredentialsList')],1),_c('ModalRoot',{attrs:{"name":_vm.DUPLICATE_MODAL_KEY},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var modalName = ref.modalName;
var active = ref.active;
return [_c('DuplicateWorkflowDialog',{attrs:{"isActive":active,"modalName":modalName}})]}}])}),_c('ModalRoot',{attrs:{"name":_vm.PERSONALIZATION_MODAL_KEY}},[_c('PersonalizationModal')],1),_c('ModalRoot',{attrs:{"name":_vm.TAGS_MANAGER_MODAL_KEY}},[_c('TagsManager')],1),_c('ModalRoot',{attrs:{"name":_vm.VERSIONS_MODAL_KEY,"keepAlive":true}},[_c('UpdatesPanel')],1),_c('ModalRoot',{attrs:{"name":_vm.VALUE_SURVEY_MODAL_KEY,"keepAlive":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('ValueSurvey',{attrs:{"isActive":active}})]}}])}),_c('ModalRoot',{attrs:{"name":_vm.WORKFLOW_OPEN_MODAL_KEY}},[_c('WorkflowOpen')],1),_c('ModalRoot',{attrs:{"name":_vm.WORKFLOW_SETTINGS_MODAL_KEY}},[_c('WorkflowSettings')],1),_c('ModalRoot',{attrs:{"name":_vm.CHANGE_PASSWORD_MODAL_KEY}},[_c('ChangePasswordModal')],1),_c('ModalRoot',{attrs:{"name":_vm.INVITE_USER_MODAL_KEY}},[_c('InviteUsersModal')],1),_c('ModalRoot',{attrs:{"name":_vm.DELETE_USER_MODAL_KEY},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var modalName = ref.modalName;
var activeId = ref.activeId;
return [_c('DeleteUserModal',{attrs:{"modalName":modalName,"activeId":activeId}})]}}])}),_c('ModalRoot',{attrs:{"name":_vm.EXECUTIONS_MODAL_KEY}},[_c('ExecutionsList')],1),_c('ModalRoot',{attrs:{"name":_vm.WORKFLOW_ACTIVE_MODAL_KEY}},[_c('ActivationModal')],1),_c('ModalRoot',{attrs:{"name":_vm.ONBOARDING_CALL_SIGNUP_MODAL_KEY}},[_c('OnboardingCallSignupModal')],1),_c('ModalRoot',{attrs:{"name":_vm.COMMUNITY_PACKAGE_INSTALL_MODAL_KEY}},[_c('CommunityPackageInstallModal')],1),_c('ModalRoot',{attrs:{"name":_vm.COMMUNITY_PACKAGE_CONFIRM_MODAL_KEY},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var modalName = ref.modalName;
var activeId = ref.activeId;
var mode = ref.mode;
return [_c('CommunityPackageManageConfirmModal',{attrs:{"modalName":modalName,"activePackageName":activeId,"mode":mode}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }