













import Vue from "vue";
import ExpandableInputBase from "./ExpandableInputBase.vue";

export default Vue.extend({
	components: { ExpandableInputBase },
	name: "ExpandableInputPreview",
	props: ["value"],
});
