







import { genericHelpers } from '@/components/mixins/genericHelpers';

import mixins from 'vue-typed-mixins';

export default mixins(
	genericHelpers,
)
	.extend({
		name: 'ExecutionTime',
		props: [
			'startTime',
		],
		computed: {
			time (): string {
				if (!this.startTime) {
					return '...';
				}
				const msPassed = this.nowTime - new Date(this.startTime).getTime();
				return this.displayTimer(msPassed);
			},
		},
		data () {
			return {
				nowTime: -1,
				intervalTimer: null as null | NodeJS.Timeout,
			};
		},
		mounted () {
			this.setNow();
			this.intervalTimer = setInterval(() => {
				this.setNow();
			}, 1000);
		},
		destroyed () {
			// Make sure that the timer gets destroyed once no longer needed
			if (this.intervalTimer !== null) {
				clearInterval(this.intervalTimer);
			}
		},
		methods: {
			setNow () {
				this.nowTime = (new Date()).getTime();
			},
		},
	});
