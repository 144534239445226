















import { IMenuItem } from '../Interface';
import Vue from 'vue';

export default Vue.extend({
	name: 'MenuItemsIterator',
	props: [
		'items',
		'root',
		'afterItemClick',
	],
	methods: {
		onClick(item: IMenuItem) {
			if (item && item.type === 'link' && item.properties) {
				const href = item.properties.href;
				if (!href) {
					return;
				}

				if (item.properties.newWindow) {
					window.open(href);
				}
				else {
					window.location.assign(item.properties.href);
				}

				if(this.afterItemClick) {
					this.afterItemClick(item.id);
				}
			}
		},
	},
});
