









import Vue from 'vue';

export default Vue.extend({
	name: "TitledList",
	props: {
		title: {
			type: String,
		},
		items: {
			type: Array,
			default: () => [],
		},
	},
});
