














import Vue from 'vue';
import camelcase from 'lodash.camelcase';
import { CategoryName } from '@/plugins/i18n';

export default Vue.extend({
	props: ['item'],
	computed: {
		categoryName() {
			return camelcase(this.item.category);
		},
	},
	methods: {
		renderCategoryName(categoryName: CategoryName) {
			const key = `nodeCreator.categoryNames.${categoryName}` as const;

			return this.$locale.exists(key) ? this.$locale.baseText(key) : categoryName;
		},
	},
});
