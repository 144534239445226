






















export default {
	name: 'TriggerIcon',
};
