



















































































import Vue from 'vue';
import Modal from './Modal.vue';
import {
	COMMUNITY_PACKAGE_INSTALL_MODAL_KEY,
	NPM_KEYWORD_SEARCH_URL,
	COMMUNITY_NODES_INSTALLATION_DOCS_URL,
	COMMUNITY_NODES_RISKS_DOCS_URL,
} from '../constants';
import mixins from 'vue-typed-mixins';
import { showMessage } from './mixins/showMessage';

export default mixins(
	showMessage,
).extend({
	name: 'CommunityPackageInstallModal',
	components: {
		Modal,
	},
	data() {
		return {
			loading: false,
			packageName: '',
			userAgreed: false,
			modalBus: new Vue(),
			checkboxWarning: false,
			infoTextErrorMessage: '',
			COMMUNITY_PACKAGE_INSTALL_MODAL_KEY,
			NPM_KEYWORD_SEARCH_URL,
			COMMUNITY_NODES_INSTALLATION_DOCS_URL,
			COMMUNITY_NODES_RISKS_DOCS_URL,
		};
	},
	methods: {
		openNPMPage() {
			this.$telemetry.track('user clicked cnr browse button', { source: 'cnr install modal' });
			window.open(NPM_KEYWORD_SEARCH_URL, '_blank');
		},
		async onInstallClick() {
			if (!this.userAgreed) {
				this.checkboxWarning = true;
			} else {
				try {
					this.$telemetry.track('user started cnr package install', { input_string: this.packageName, source: 'cnr settings page' });
					this.infoTextErrorMessage = '';
					this.loading = true;
					await this.$store.dispatch('communityNodes/installPackage', this.packageName);
					// TODO: We need to fetch a fresh list of installed packages until proper response is implemented on the back-end
					await this.$store.dispatch('communityNodes/fetchInstalledPackages');
					this.loading = false;
					this.modalBus.$emit('close');
					this.$showMessage({
						title: this.$locale.baseText('settings.communityNodes.messages.install.success'),
						type: 'success',
					});
				} catch(error) {
					if(error.httpStatusCode && error.httpStatusCode === 400) {
						this.infoTextErrorMessage = error.message;
					} else {
						this.$showError(
							error,
							this.$locale.baseText('settings.communityNodes.messages.install.error'),
						);
					}
				} finally {
					this.loading = false;
				}
			}
		},
		onCheckboxChecked() {
			this.checkboxWarning = false;
		},
		onModalClose() {
			return !this.loading;
		},
		onInputBlur() {
			this.packageName = this.packageName.replaceAll('npm i ', '').replaceAll('npm install ', '');
		},
		onMoreInfoTopClick() {
			this.$telemetry.track('user clicked cnr docs link', { source: 'install package modal top' });
		},
		onLearnMoreLinkClick() {
			this.$telemetry.track('user clicked cnr docs link', { source: 'install package modal bottom' });
		},
	},
});
