
















import { genericHelpers } from '@/components/mixins/genericHelpers';
import Card from '@/components/WorkflowCard.vue';
import mixins from 'vue-typed-mixins';
import NodeList from '@/components/NodeList.vue';

export default mixins(genericHelpers).extend({
	name: 'CollectionCard',
	props: {
		loading: {
			type: Boolean,
		},
		collection: {
			type: Object,
		},
	},
	components: {
		Card,
		NodeList,
	},
	methods: {
		onClick(e: MouseEvent) {
			this.$emit('click', e);
		},
	},
});
