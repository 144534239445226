






































import Vue from "vue";

export default Vue.extend({
	name: "Modal",
	props: {
		name: {
			type: String,
		},
		title: {
			type: String,
		},
		subtitle: {
			type: String,
		},
		eventBus: {
			type: Vue,
		},
		showClose: {
			type: Boolean,
			default: true,
		},
		loading: {
			type: Boolean,
		},
		classic: {
			type: Boolean,
		},
		beforeClose: {
			type: Function,
		},
		customClass: {
			type: String,
		},
		center: {
			type: Boolean,
		},
		width: {
			type: String,
			default: '50%',
		},
		minWidth: {
			type: String,
		},
		maxWidth: {
			type: String,
		},
		height: {
			type: String,
		},
		minHeight: {
			type: String,
		},
		maxHeight: {
			type: String,
		},
		scrollable: {
			type: Boolean,
			default: false,
		},
		centerTitle: {
			type: Boolean,
			default: false,
		},
		closeOnClickModal: {
			type: Boolean,
			default: true,
		},
		closeOnPressEscape: {
			type: Boolean,
			default: true,
		},
	},
	mounted() {
		window.addEventListener('keydown', this.onWindowKeydown);

		if (this.$props.eventBus) {
			this.$props.eventBus.$on('close', () => {
				this.closeDialog();
			});

			this.$props.eventBus.$on('closeAll', () => {
				this.closeAllDialogs();
			});
		}

		const activeElement = document.activeElement as HTMLElement;
		if (activeElement) {
			activeElement.blur();
		}
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.onWindowKeydown);
	},
	methods: {
		onWindowKeydown(event: KeyboardEvent) {
			if (!this.isActive) {
				return;
			}

			if (event && event.keyCode === 13) {
				this.handleEnter();
			}
		},
		handleEnter() {
			if (this.isActive) {
				this.$emit('enter');
			}
		},
		closeAllDialogs() {
			this.$store.commit('ui/closeAllModals');
		},
		async closeDialog() {
			if (this.beforeClose) {
				const shouldClose = await this.beforeClose();
				if (shouldClose === false) { // must be strictly false to stop modal from closing
					return;
				}
			}

			this.$store.commit('ui/closeModal', this.$props.name);
		},
		getCustomClass() {
			let classes = this.$props.customClass || '';

			if (this.$props.classic) {
				classes = `${classes} classic`;
			}

			return classes;
		},
	},
	computed: {
		isActive(): boolean {
			return this.$store.getters['ui/isModalActive'](this.$props.name);
		},
		visible(): boolean {
			return this.$store.getters['ui/isModalOpen'](this.$props.name);
		},
		styles() {
			const styles: {[prop: string]: string} = {};
			if (this.height) {
				styles['--dialog-height'] = this.height;
			}
			if (this.minHeight) {
				styles['--dialog-min-height'] = this.minHeight;
			}
			if (this.maxHeight) {
				styles['--dialog-max-height'] = this.maxHeight;
			}
			if (this.maxWidth) {
				styles['--dialog-max-width'] = this.maxWidth;
			}
			if (this.minWidth) {
				styles['--dialog-min-width'] = this.minWidth;
			}
			return styles;
		},
	},
});
