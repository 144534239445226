




























import Vue from 'vue';

export default Vue.extend({
	name: 'NodeTitle',
	props: {
		value: {
			type: String,
		},
		nodeType: {},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			editName: false,
			newName: '',
		};
	},
	methods: {
		onEdit() {
			this.newName = this.value;
			this.editName = true;
			this.$nextTick(() => {
				const input = this.$refs.input;
				if (input) {
					(input as HTMLInputElement).focus();
				}
			});
		},
		onRename() {
			if (this.newName.trim() !== '') {
				this.$emit('input', this.newName.trim());
			}

			this.editName = false;
		},
	},
});
