

































import { genericHelpers } from '@/components/mixins/genericHelpers';
import mixins from 'vue-typed-mixins';
import TemplateCard from './TemplateCard.vue';

export default mixins(genericHelpers).extend({
	name: 'TemplateList',
	props: {
		infiniteScrollEnabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
		},
		useWorkflowButton: {
			type: Boolean,
			default: false,
		},
		workflows: {
			type: Array,
		},
		totalWorkflows: {
			type: Number,
		},
	},
	mounted() {
		if (this.infiniteScrollEnabled) {
			window.addEventListener('scroll', this.onScroll);
		}
	},
	destroyed() {
		window.removeEventListener('scroll', this.onScroll);
	},
	components: {
		TemplateCard,
	},
	methods: {
		onScroll() {
			const el = this.$refs.loader;
			if (!el || this.loading) {
				return;
			}

			const rect = (el as Element).getBoundingClientRect();
			const inView =
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth);

			if (inView) {
				this.$emit('loadMore');
			}
		},
		onCardClick(event: MouseEvent, id: string) {
			this.$emit('openTemplate', {event, id});
		},
		onUseWorkflow(event: MouseEvent, id: string) {
			this.$emit('useWorkflow', {event, id});
		},
	},
});
