




































































import { showMessage } from '@/components/mixins/showMessage';
import { IUser } from '@/Interface';
import mixins from 'vue-typed-mixins';

import SettingsView from './SettingsView.vue';
import CopyInput from '../components/CopyInput.vue';

export default mixins(
	showMessage,
).extend({
	name: 'SettingsPersonalView',
	components: {
		SettingsView,
		CopyInput,
	},
	data() {
		return {
			loading: false,
			mounted: false,
			apiKey: '',
			apiPlaygroundPath: '',
		};
	},
	mounted() {
		this.getApiKey();
		const baseUrl = this.$store.getters.getBaseUrl;
		const apiPath = this.$store.getters['settings/publicApiPath'];
		const latestVersion = this.$store.getters['settings/publicApiLatestVersion'];
		this.apiPlaygroundPath = `${baseUrl}${apiPath}/v${latestVersion}/docs`;
	},
	computed: {
		currentUser(): IUser {
			return this.$store.getters['users/currentUser'];
		},
	},
	methods: {
		async showDeleteModal() {
			const confirmed = await this.confirmMessage(
				this.$locale.baseText('settings.api.delete.description'),
				this.$locale.baseText('settings.api.delete.title'),
				null,
				this.$locale.baseText('settings.api.delete.button'),
				this.$locale.baseText('generic.cancel'),
			);
			if (confirmed) {
				this.deleteApiKey();
			}
		},
		async getApiKey() {
			try {
				this.apiKey = await this.$store.dispatch('settings/getApiKey');
			} catch (error) {
				this.$showError(error, this.$locale.baseText('settings.api.view.error'));
			} finally {
				this.mounted = true;
			}
		},
		async createApiKey() {
			this.loading = true;

			try {
				this.apiKey = await this.$store.dispatch('settings/createApiKey');
			} catch (error) {
				this.$showError(error, this.$locale.baseText('settings.api.create.error'));
			} finally {
				this.loading = false;
				this.$telemetry.track('User clicked create API key button');
			}
		},
		async deleteApiKey() {
			try {
				await this.$store.dispatch('settings/deleteApiKey');
				this.$showMessage({ title: this.$locale.baseText("settings.api.delete.toast"), type: 'success' });
				this.apiKey = '';
			} catch (error) {
				this.$showError(error, this.$locale.baseText('settings.api.delete.error'));
			} finally {
				this.$telemetry.track('User clicked delete API key button');
			}
		},
		onCopy() {
			this.$telemetry.track('User clicked copy API key button');
		},
	},
});
