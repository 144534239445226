




















export default {
	props: ["text", "type"],
};
