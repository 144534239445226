















import { ITaskData } from 'n8n-workflow';
import Vue from 'vue';

export default Vue.extend({
	props: {
		taskData: {}, // ITaskData
	},

	computed: {
		runTaskData(): ITaskData {
			return this.taskData as ITaskData;
		},
		runMetadata(): { executionTime: number; startTime: string } | null {
			if (!this.runTaskData) {
				return null;
			}
			return {
				executionTime: this.runTaskData.executionTime,
				startTime: new Date(this.runTaskData.startTime).toLocaleString(),
			};
		},
	},
});
