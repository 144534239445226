




























import {IFakeDoor} from '@/Interface';
import Vue from 'vue';

export default Vue.extend({
	name: 'FeatureComingSoon',
	props: {
		featureId: {
			type: String,
			required: true,
		},
		showTitle: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		userId(): string {
			return this.$store.getters['users/currentUserId'];
		},
		versionCli(): string {
			return this.$store.getters['settings/versionCli'];
		},
		instanceId(): string {
			return this.$store.getters.instanceId;
		},
		featureInfo(): IFakeDoor {
			return this.$store.getters['ui/getFakeDoorById'](this.featureId);
		},
	},
	methods: {
		openLinkPage() {
			window.open(`${this.featureInfo.linkURL}&u=${this.instanceId}#${this.userId}&v=${this.versionCli}`, '_blank');
			this.$telemetry.track('user clicked feature waiting list button', {feature: this.featureId});
		},
	},
});
