











import Vue from 'vue';

export default Vue.extend({
	name: 'TemplateDetailsBlock',
	props: {
		title: {
			type: String,
		},
	},
});
