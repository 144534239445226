






















































import { IResourceLocatorResultExpanded } from '@/Interface';
import Vue, { PropType } from 'vue';

const SEARCH_BAR_HEIGHT_PX = 40;
const SCROLL_MARGIN_PX = 10;

export default Vue.extend({
	name: 'resource-locator-dropdown',
	props: {
		value: {
			type: [String, Number],
		},
		show: {
			type: Boolean,
			default: false,
		},
		resources: {
			type: Array as PropType<IResourceLocatorResultExpanded[]>,
		},
		filterable: {
			type: Boolean,
		},
		loading: {
			type: Boolean,
		},
		filter: {
			type: String,
		},
		hasMore: {
			type: Boolean,
		},
		errorView: {
			type: Boolean,
		},
		filterRequired: {
			type: Boolean,
		},
		width: {
			type: Number,
		},
	},
	data() {
		return {
			hoverIndex: 0,
			showHoverUrl: false,
		};
	},
	mounted() {
		this.$on('keyDown', this.onKeyDown);
	},
	computed: {
		sortedResources(): IResourceLocatorResultExpanded[] {
			const seen = new Set();
			const { selected, notSelected } = this.resources.reduce((acc, item: IResourceLocatorResultExpanded) => {
				if (seen.has(item.value)) {
					return acc;
				}
				seen.add(item.value);

				if (this.value && item.value === this.value) {
					acc.selected = item;
				} else {
					acc.notSelected.push(item);
				}

				return acc;
			}, { selected: null as IResourceLocatorResultExpanded | null, notSelected: [] as IResourceLocatorResultExpanded[] });

			if (selected) {
				return [
					selected,
					...notSelected,
				];
			}

			return notSelected;
		},
	},
	methods: {
		openUrl(event: MouseEvent ,url: string) {
			event.preventDefault();
			event.stopPropagation();

			window.open(url, '_blank');
		},
		onKeyDown(e: KeyboardEvent) {
			const container = this.$refs.resultsContainer as HTMLElement;

			if (e.key === 'ArrowDown') {
				if (this.hoverIndex < this.sortedResources.length - 1) {
					this.hoverIndex++;

					const items = this.$refs[`item-${this.hoverIndex}`] as HTMLElement[];
					if (container && Array.isArray(items) && items.length === 1) {
						const item = items[0];
						if ((item.offsetTop + item.clientHeight) > (container.scrollTop + container.offsetHeight)) {
							const top = item.offsetTop - container.offsetHeight + item.clientHeight;
							container.scrollTo({ top });
						}
					}
				}
			}
			else if (e.key === 'ArrowUp') {
				if (this.hoverIndex > 0) {
					this.hoverIndex--;

					const searchOffset = this.filterable ? SEARCH_BAR_HEIGHT_PX : 0;
					const items = this.$refs[`item-${this.hoverIndex}`] as HTMLElement[];
					if (container && Array.isArray(items) && items.length === 1) {
						const item = items[0];
						if (item.offsetTop <= container.scrollTop + searchOffset) {
							container.scrollTo({ top: item.offsetTop - searchOffset });
						}
					}
				}
			}
			else if (e.key === 'Enter') {
				this.$emit('input', this.sortedResources[this.hoverIndex].value);
			}

		},
		onFilterInput(value: string) {
			this.$emit('filter', value);
		},
		onSearchBlur() {
			this.$emit('hide');
		},
		onItemClick(selected: string) {
			this.$emit('input', selected);
		},
		onItemHover(index: number) {
			this.hoverIndex = index;

			setTimeout(() => {
				if (this.hoverIndex === index) {
					this.showHoverUrl = true;
				}
			}, 250);
		},
		onItemHoverLeave() {
			this.showHoverUrl = false;
		},
		onResultsEnd() {
			if (this.loading || !this.hasMore) {
				return;
			}

			const container = this.$refs.resultsContainer as HTMLElement;
			if (container) {
				const diff = container.offsetHeight - (container.scrollHeight - container.scrollTop);
				if (diff > -(SCROLL_MARGIN_PX)  && diff < SCROLL_MARGIN_PX) {
					this.$emit('loadMore');
				}
			}
		},
	},
	watch: {
		show(toShow) {
			if (toShow) {
				this.hoverIndex = 0;
				this.showHoverUrl = false;
			}
			setTimeout(() => {
				if (toShow && this.filterable && this.$refs.search) {
					(this.$refs.search as HTMLElement).focus();
				}
			}, 0);
		},
		loading() {
			setTimeout(this.onResultsEnd, 0); // in case of filtering
		},
	},
});
