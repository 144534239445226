
































import { INodeCreateElement } from '@/Interface';

import Vue from 'vue';
import CreatorItem from './CreatorItem.vue';

export default Vue.extend({
	name: 'ItemIterator',
	components: {
		CreatorItem,
	},
	props: ['elements', 'activeIndex', 'disabled', 'transitionsEnabled'],
	methods: {
		emit(eventName: string, element: INodeCreateElement, event: Event) {
			if (this.$props.disabled) {
				return;
			}

			this.$emit(eventName, { element, event });
		},
		beforeEnter(el: HTMLElement) {
			el.style.height = '0';
		},
		enter(el: HTMLElement) {
			el.style.height = `${el.scrollHeight}px`;
		},
		beforeLeave(el: HTMLElement) {
			el.style.height = `${el.scrollHeight}px`;
		},
		leave(el: HTMLElement) {
			el.style.height = '0';
		},
	},
});
