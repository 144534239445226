


















import Vue from 'vue';
import mixins from 'vue-typed-mixins';

export default Vue.extend({
	props: {
		isGoogleOAuthType: {
			type: Boolean,
		},
	},
	computed: {
		basePath(): string {
			return this.$store.getters.getBaseUrl;
		},
	},
});
