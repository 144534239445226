









import Vue from 'vue';
import TitledList from '@/components/TitledList.vue';

export default Vue.extend({
	name: 'ParameterIssues',
	components: {
		TitledList,
	},
	props: [
		'issues',
	],
});
