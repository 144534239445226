










































import { INVITE_USER_MODAL_KEY, VIEWS } from '@/constants';
import { mapGetters } from 'vuex';

import SettingsView from './SettingsView.vue';
import PageAlert from '../components/PageAlert.vue';
import { IUser } from '@/Interface';
import mixins from 'vue-typed-mixins';
import { showMessage } from '@/components/mixins/showMessage';

export default mixins(showMessage).extend({
	name: 'SettingsUsersView',
	components: {
		SettingsView,
		PageAlert,
	},
	async mounted() {
		if (!this.showUMSetupWarning) {
			await this.$store.dispatch('users/fetchUsers');
		}
	},
	computed: {
		...mapGetters('users', ['allUsers', 'currentUserId', 'showUMSetupWarning']),
		...mapGetters('settings', ['isSmtpSetup']),
	},
	methods: {
		redirectToSetup() {
			this.$router.push({name: VIEWS.SETUP});
		},
		onInvite() {
			this.$store.dispatch('ui/openModal', INVITE_USER_MODAL_KEY);
		},
		async onDelete(userId: string) {
			const getUserById = this.$store.getters['users/getUserById'];
			const user = getUserById(userId) as IUser | null;
			if (user) {
				this.$store.dispatch('ui/openDeleteUserModal', { id: userId });
			}
		},
		async onReinvite(userId: string) {
			const getUserById = this.$store.getters['users/getUserById'];
			const user = getUserById(userId) as IUser | null;
			if (user) {
				try {
					await this.$store.dispatch('users/reinviteUser', { id: user.id });

					this.$showToast({
						type: 'success',
						title: this.$locale.baseText('settings.users.inviteResent'),
						message: this.$locale.baseText(
							'settings.users.emailSentTo',
							{ interpolate: { email: user.email || '' } },
						),
					});
				} catch (e) {
					this.$showError(e, this.$locale.baseText('settings.users.userReinviteError'));
				}
			}
		},
	},
});
