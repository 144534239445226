








































import mixins from 'vue-typed-mixins';
import {ICredentialsResponse, IUser} from "@/Interface";
import {ICredentialType} from "n8n-workflow";
import {EnterpriseEditionFeature} from '@/constants';
import {showMessage} from "@/components/mixins/showMessage";
import CredentialIcon from '@/components/CredentialIcon.vue';
import {getCredentialPermissions, IPermissions} from "@/permissions";
import {mapGetters} from "vuex";
import dateformat from "dateformat";

export const CREDENTIAL_LIST_ITEM_ACTIONS = {
	OPEN: 'open',
	DELETE: 'delete',
};

export default mixins(
	showMessage,
).extend({
	data() {
		return {
			EnterpriseEditionFeature,
		};
	},
	components: {
		CredentialIcon,
	},
	props: {
		data: {
			type: Object,
			required: true,
			default: (): ICredentialsResponse => ({
				id: '',
				createdAt: '',
				updatedAt: '',
				type: '',
				name: '',
				nodesAccess: [],
				sharedWith: [],
				ownedBy: {} as IUser,
			}),
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('users', ['currentUser']),
		credentialType(): ICredentialType {
			return this.$store.getters['credentials/getCredentialTypeByName'](this.data.type);
		},
		credentialPermissions(): IPermissions {
			return getCredentialPermissions(this.currentUser, this.data, this.$store);
		},
		actions(): Array<{ label: string; value: string; }> {
			return [
				{
					label: this.$locale.baseText('credentials.item.open'),
					value: CREDENTIAL_LIST_ITEM_ACTIONS.OPEN,
				},
			].concat(this.credentialPermissions.delete ? [{
				label: this.$locale.baseText('credentials.item.delete'),
				value: CREDENTIAL_LIST_ITEM_ACTIONS.DELETE,
			}]: []);
		},
		formattedCreatedAtDate(): string {
			const currentYear = new Date().getFullYear();

			return dateformat(this.data.createdAt, `d mmmm${this.data.createdAt.startsWith(currentYear) ? '' : ', yyyy'}`);
		},
	},
	methods: {
		async onClick() {
			this.$store.dispatch('ui/openExistingCredential', { id: this.data.id});
		},
		async onAction(action: string) {
			if (action === CREDENTIAL_LIST_ITEM_ACTIONS.OPEN) {
				this.onClick();
			} else if (action === CREDENTIAL_LIST_ITEM_ACTIONS.DELETE) {
				const deleteConfirmed = await this.confirmMessage(
					this.$locale.baseText('credentialEdit.credentialEdit.confirmMessage.deleteCredential.message', {
						interpolate: { savedCredentialName: this.data.name },
					}),
					this.$locale.baseText('credentialEdit.credentialEdit.confirmMessage.deleteCredential.headline'),
					null,
					this.$locale.baseText('credentialEdit.credentialEdit.confirmMessage.deleteCredential.confirmButtonText'),
				);

				if (deleteConfirmed) {
					await this.$store.dispatch('credentials/deleteCredential', {
						id: this.data.id,
					});
				}
			}
		},
	},
});
