






















































import Vue from 'vue';
import { mapGetters } from 'vuex';

import ModalDrawer from './ModalDrawer.vue';
import TimeAgo from './TimeAgo.vue';
import VersionCard from './VersionCard.vue';
import { VERSIONS_MODAL_KEY } from '../constants';

export default Vue.extend({
	name: 'UpdatesPanel',
	components: {
		ModalDrawer,
		VersionCard,
		TimeAgo,
	},
	computed: {
		...mapGetters('versions', ['nextVersions', 'currentVersion', 'infoUrl']),
	},
	data() {
		return {
			VERSIONS_MODAL_KEY,
		};
	},
});
