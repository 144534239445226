












import N8nIcon from '../N8nIcon';

import Vue from 'vue';

export default Vue.extend({
	name: 'n8n-spinner',
	components: {
		N8nIcon,
	},
	props: {
		size: {
			type: String,
			validator: function (value: string): boolean {
				return ['small', 'medium', 'large'].includes(value);
			},
		},
		type: {
			type: String,
			validator: function (value: string): boolean {
				return ['dots', 'ring'].includes(value);
			},
			default: 'dots',
		},
	},
});
