












































































































import {
	IUpdateInformation,
} from '@/Interface';

import {
	INodeParameters,
	INodePropertyCollection,
} from 'n8n-workflow';

import { get } from 'lodash';

import { genericHelpers } from '@/components/mixins/genericHelpers';

import mixins from 'vue-typed-mixins';

export default mixins(genericHelpers)
	.extend({
		name: 'FixedCollectionParameter',
		props: [
			'nodeValues', // INodeParameters
			'parameter', // INodeProperties
			'path', // string
			'values', // INodeParameters
		],
		data() {
			return {
				selectedOption: undefined,
			};
		},

		computed: {
			getPlaceholderText(): string {
				const placeholder = this.$locale.nodeText().placeholder(this.parameter, this.path);
				return placeholder ? placeholder : this.$locale.baseText('fixedCollectionParameter.choose');
			},
			getProperties(): INodePropertyCollection[] {
				const returnProperties = [];
				let tempProperties;
				for (const name of this.propertyNames) {
					tempProperties = this.getOptionProperties(name);
					if (tempProperties !== undefined) {
						returnProperties.push(tempProperties);
					}
				}
				return returnProperties;
			},
			multipleValues(): boolean {
				if (this.parameter.typeOptions !== undefined && this.parameter.typeOptions.multipleValues === true) {
					return true;
				}
				return false;
			},

			parameterOptions(): INodePropertyCollection[] {
				if (this.multipleValues === true) {
					return this.parameter.options;
				}

				return (this.parameter.options as INodePropertyCollection[]).filter((option) => {
					return !this.propertyNames.includes(option.name);
				});
			},
			propertyNames(): string[] {
				if (this.values) {
					return Object.keys(this.values);
				}
				return [];
			},
			sortable(): string {
				return this.parameter.typeOptions && this.parameter.typeOptions.sortable;
			},
		},
		methods: {
			deleteOption(optionName: string, index?: number) {
				const currentOptionsOfSameType = this.values[optionName];
				if (!currentOptionsOfSameType || currentOptionsOfSameType.length > 1) {
					// it's not the only option of this type, so just remove it.
					this.$emit('valueChanged', {
						name: this.getPropertyPath(optionName, index),
						value: undefined,
					});
				} else {
					// it's the only option, so remove the whole type
					this.$emit('valueChanged', {
						name: this.getPropertyPath(optionName),
						value: undefined,
					});
				}
			},
			getPropertyPath(name: string, index?: number) {
				return `${this.path}.${name}` + (index !== undefined ? `[${index}]` : '');
			},
			getOptionProperties(optionName: string): INodePropertyCollection | undefined {
				for (const option of this.parameter.options) {
					if (option.name === optionName) {
						return option;
					}
				}

				return undefined;
			},
			moveOptionDown(optionName: string, index: number) {
				this.values[optionName].splice(index + 1, 0, this.values[optionName].splice(index, 1)[0]);

				const parameterData = {
					name: this.getPropertyPath(optionName),
					value: this.values[optionName],
				};

				this.$emit('valueChanged', parameterData);
			},
			moveOptionUp(optionName: string, index: number) {
				this.values[optionName].splice(index - 1, 0, this.values[optionName].splice(index, 1)[0]);

				const parameterData = {
					name: this.getPropertyPath(optionName),
					value: this.values[optionName],
				};

				this.$emit('valueChanged', parameterData);
			},
			optionSelected(optionName: string) {
				const option = this.getOptionProperties(optionName);
				if (option === undefined) {
					return;
				}
				const name = `${this.path}.${option.name}`;

				let parameterData;

				const newParameterValue: INodeParameters = {};

				for (const optionParameter of option.values) {
					if (optionParameter.type === 'fixedCollection' && optionParameter.typeOptions !== undefined && optionParameter.typeOptions.multipleValues === true) {
						newParameterValue[optionParameter.name] = {};
					} else if (optionParameter.typeOptions !== undefined && optionParameter.typeOptions.multipleValues === true) {
						// Multiple values are allowed so append option to array
						newParameterValue[optionParameter.name] = get(this.nodeValues, `${this.path}.${optionParameter.name}`, []);
						if (Array.isArray(optionParameter.default)) {
							(newParameterValue[optionParameter.name] as INodeParameters[]).push(...JSON.parse(JSON.stringify(optionParameter.default)));
						} else if (optionParameter.default !== '' && typeof optionParameter.default !== 'object') {
							(newParameterValue[optionParameter.name] as INodeParameters[]).push(JSON.parse(JSON.stringify(optionParameter.default)));
						}
					} else {
						// Add a new option
						newParameterValue[optionParameter.name] = JSON.parse(JSON.stringify(optionParameter.default));
					}
				}

				let newValue;
				if (this.multipleValues === true) {
					newValue = get(this.nodeValues, name, []);

					newValue.push(newParameterValue);
				} else {
					newValue = newParameterValue;
				}

				parameterData = {
					name,
					value: newValue,
				};

				this.$emit('valueChanged', parameterData);
				this.selectedOption = undefined;
			},
			valueChanged(parameterData: IUpdateInformation) {
				this.$emit('valueChanged', parameterData);
			},
		},
		beforeCreate: function () { // tslint:disable-line
			// Because we have a circular dependency on ParameterInputList import it here
			// to not break Vue.
			this.$options!.components!.ParameterInputList = require('./ParameterInputList.vue').default;
		},
	});
