
















import Vue from 'vue';

import { IUpdateInformation } from '../../Interface';

import ParameterInputExpanded from '../ParameterInputExpanded.vue';

export default Vue.extend({
	name: 'CredentialsInput',
	props: [
		'credentialProperties',
		'credentialData', // ICredentialsDecryptedResponse
		'documentationUrl',
		'showValidationWarnings',
	],
	components: {
		ParameterInputExpanded,
	},
	methods: {
		valueChanged(parameterData: IUpdateInformation) {
			const name = parameterData.name.split('.').pop();

			this.$emit('change', {
				name,
				value: parameterData.value,
			});
		},
	},
});
