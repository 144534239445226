


























import { isResourceLocatorValue } from '@/typeGuards';
import { NodeParameterValueType } from 'n8n-workflow';
import Vue, { PropType } from 'vue';
import { isValueExpression } from './helpers';

export default Vue.extend({
	name: 'parameter-options',
	props: {
		parameter: {
			type: Object,
		},
		isReadOnly: {
			type: Boolean,
		},
		value: {
			type: [Object, String, Number, Boolean] as PropType<NodeParameterValueType>,
		},
		showOptions: {
			type: Boolean,
			default: true,
		},
		showExpressionSelector: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		isDefault (): boolean {
			return this.parameter.default === this.value;
		},
		isValueExpression(): boolean {
			return isValueExpression(this.parameter, this.value);
		},
		shouldShowOptions (): boolean {
			if (this.isReadOnly === true) {
				return false;
			}

			if (this.parameter.type === 'collection' || this.parameter.type === 'credentialsSelect') {
				return false;
			}

			if (this.showOptions === true) {
				return true;
			}

			return false;
		},
		selectedView () {
			if (this.isValueExpression) {
				return 'expression';
			}

			return 'fixed';
		},
		hasRemoteMethod (): boolean {
			return !!this.getArgument('loadOptionsMethod') || !!this.getArgument('loadOptions');
		},
		actions (): Array<{label: string, value: string, disabled?: boolean}> {
			const actions = [
				{
					label: this.$locale.baseText('parameterInput.resetValue'),
					value: 'resetValue',
					disabled: this.isDefault,
				},
			];

			if (this.hasRemoteMethod || (this.parameter.type === 'resourceLocator' && isResourceLocatorValue(this.value) && this.value.mode === 'list')) {
				return [
					{
						label: this.$locale.baseText('parameterInput.refreshList'),
						value: 'refreshOptions',
					},
					...actions,
				];
			}

			return actions;
		},
	},
	methods: {
		onMenuToggle(visible: boolean) {
			this.$emit('menu-expanded', visible);
		},
		onViewSelected(selected: string) {
			if (selected === 'expression' ) {
				this.$emit('optionSelected', this.isValueExpression? 'openExpression': 'addExpression');
			}

			if (selected === 'fixed' && this.isValueExpression) {
				this.$emit('optionSelected', 'removeExpression');
			}
		},
		getArgument (argumentName: string): string | number | boolean | undefined {
			if (this.parameter.typeOptions === undefined) {
				return undefined;
			}

			if (this.parameter.typeOptions[argumentName] === undefined) {
				return undefined;
			}

			return this.parameter.typeOptions[argumentName];
		},
	},
});
