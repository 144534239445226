


























import { showMessage } from '@/components/mixins/showMessage';
import { workflowActivate } from '@/components/mixins/workflowActivate';

import mixins from 'vue-typed-mixins';
import { mapGetters } from "vuex";

import { getActivatableTriggerNodes } from './helpers';

export default mixins(
	showMessage,
	workflowActivate,
)
	.extend(
		{
			name: 'WorkflowActivator',
			props: [
				'workflowActive',
				'workflowId',
			],
			computed: {
				...mapGetters({
					dirtyState: "getStateIsDirty",
				}),
				nodesIssuesExist (): boolean {
					return this.$store.getters.nodesIssuesExist;
				},
				isWorkflowActive (): boolean {
					const activeWorkflows = this.$store.getters.getActiveWorkflows;
					return activeWorkflows.includes(this.workflowId);
				},
				couldNotBeStarted (): boolean {
					return this.workflowActive === true && this.isWorkflowActive !== this.workflowActive;
				},
				getActiveColor (): string {
					if (this.couldNotBeStarted === true) {
						return '#ff4949';
					}
					return '#13ce66';
				},
				isCurrentWorkflow(): boolean {
					return this.$store.getters['workflowId'] === this.workflowId;
				},
				disabled(): boolean {
					const isNewWorkflow = !this.workflowId;
					if (isNewWorkflow || this.isCurrentWorkflow) {
						return !this.workflowActive && !this.containsTrigger;
					}

					return false;
				},
				containsTrigger(): boolean {
					const foundTriggers = getActivatableTriggerNodes(this.$store.getters.workflowTriggerNodes);
					return foundTriggers.length > 0;
				},
			},
			methods: {
				async activeChanged (newActiveState: boolean) {
					return this.updateWorkflowActivation(this.workflowId, newActiveState);
				},
				async displayActivationError () {
					let errorMessage: string;
					try {
						const errorData = await this.restApi().getActivationError(this.workflowId);

						if (errorData === undefined) {
							errorMessage = this.$locale.baseText('workflowActivator.showMessage.displayActivationError.message.errorDataUndefined');
						} else {
							errorMessage = this.$locale.baseText(
								'workflowActivator.showMessage.displayActivationError.message.errorDataNotUndefined',
								{ interpolate: { message: errorData.error.message } },
							);
						}
					} catch (error) {
						errorMessage = this.$locale.baseText('workflowActivator.showMessage.displayActivationError.message.catchBlock');
					}

					this.$showMessage({
						title: this.$locale.baseText('workflowActivator.showMessage.displayActivationError.title'),
						message: errorMessage,
						type: 'warning',
						duration: 0,
					});
				},
			},
		},
	);
