






import Vue from 'vue';

export default Vue.extend({
	name: 'n8n-text',
	props: {
		bold: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: 'medium',
			validator: (value: string): boolean => ['xsmall', 'small', 'mini', 'medium', 'large', 'xlarge'].includes(value),
		},
		color: {
			type: String,
			validator: (value: string): boolean => ['primary', 'text-dark', 'text-base', 'text-light', 'text-xlight', 'danger'].includes(value),
		},
		align: {
			type: String,
			validator: (value: string): boolean => ['right', 'left', 'center'].includes(value),
		},
		compact: {
			type: Boolean,
			default: false,
		},
		tag: {
			type: String,
			default: 'span',
		},
	},
	computed: {
		classes() {
			const applied = [];
			if (this.align) {
				applied.push(`align-${this.align}`);
			}
			if (this.color) {
				applied.push(this.color);
			}

			if (this.compact) {
				applied.push('compact');
			}

			applied.push(`size-${this.size}`);

			applied.push(this.bold? 'bold': 'regular');

			return applied.map((c) => (this.$style as { [key: string]: string })[c]);
		}
	},
});
