
























































import { PublicInstalledPackage } from 'n8n-workflow';
import mixins from 'vue-typed-mixins';
import {
	NPM_PACKAGE_DOCS_BASE_URL,
	COMMUNITY_PACKAGE_MANAGE_ACTIONS,
} from '../constants';
import { showMessage } from './mixins/showMessage';

export default mixins(
	showMessage,
).extend({
	name: 'CommunityPackageCard',
	props: {
		communityPackage: {
			type: Object as () => PublicInstalledPackage,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			packageActions: [
				{
					label: this.$locale.baseText('settings.communityNodes.viewDocsAction.label'),
					value: COMMUNITY_PACKAGE_MANAGE_ACTIONS.VIEW_DOCS,
					type: 'external-link',
				},
				{
					label: this.$locale.baseText('settings.communityNodes.uninstallAction.label'),
					value: COMMUNITY_PACKAGE_MANAGE_ACTIONS.UNINSTALL,
				},
			],
		};
	},
	methods: {
		async onAction(value: string) {
			switch (value) {
				case COMMUNITY_PACKAGE_MANAGE_ACTIONS.VIEW_DOCS:
					this.$telemetry.track('user clicked to browse the cnr package documentation', {
						package_name: this.communityPackage.packageName,
						package_version: this.communityPackage.installedVersion,
					});
					window.open(`${NPM_PACKAGE_DOCS_BASE_URL}${this.communityPackage.packageName}`, '_blank');
					break;
				case COMMUNITY_PACKAGE_MANAGE_ACTIONS.UNINSTALL:
					this.$store.dispatch('ui/openCommunityPackageUninstallConfirmModal', this.communityPackage.packageName);
					break;
				default:
					break;
			}
		},
		onUpdateClick() {
			this.$store.dispatch('ui/openCommunityPackageUpdateConfirmModal', this.communityPackage.packageName);
		},
	},
});
