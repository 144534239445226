





















import {
	IBinaryData,
	IRunData,
	IRunExecutionData,
} from 'n8n-workflow';

import BinaryDataDisplayEmbed from '@/components/BinaryDataDisplayEmbed.vue';

import { nodeHelpers } from '@/components/mixins/nodeHelpers';

import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';

export default mixins(
	nodeHelpers,
	restApi,
)
	.extend({
		name: 'BinaryDataDisplay',
		components: {
			BinaryDataDisplayEmbed,
		},
		props: [
			'displayData', // IBinaryDisplayData
			'windowVisible', // boolean
		],
		computed: {
			binaryData (): IBinaryData | null {
				const binaryData = this.getBinaryData(this.workflowRunData, this.displayData.node, this.displayData.runIndex, this.displayData.outputIndex);

				if (binaryData.length === 0) {
					return null;
				}

				if (this.displayData.index >= binaryData.length || binaryData[this.displayData.index][this.displayData.key] === undefined) {
					return null;
				}

				const binaryDataItem: IBinaryData = binaryData[this.displayData.index][this.displayData.key];

				return binaryDataItem;
			},

			embedClass (): string[] {
				// @ts-ignore
				if (this.binaryData! !== null && this.binaryData!.mimeType! !== undefined && (this.binaryData!.mimeType! as string).startsWith('image')) {
					return ['image'];
				}
				return ['other'];
			},

			workflowRunData (): IRunData | null {
				const workflowExecution = this.$store.getters.getWorkflowExecution;
				if (workflowExecution === null) {
					return null;
				}
				const executionData: IRunExecutionData = workflowExecution.data;
				return executionData.resultData.runData;
			},

		},
		methods: {
			closeWindow () {
				// Handle the close externally as the visible parameter is an external prop
				// and is so not allowed to be changed here.
				this.$emit('close');
				return false;
			},
		},
	});
