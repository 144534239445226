var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.tableData.columns && _vm.tableData.columns.length === 0)?_c('table',{class:_vm.$style.table},[_c('tr',[_c('th',{class:_vm.$style.emptyCell}),_c('th',{class:_vm.$style.tableRightMargin})]),_vm._l((_vm.tableData.data),function(row,index1){return _c('tr',{key:index1},[_c('td',[_c('n8n-text',[_vm._v(_vm._s(_vm.$locale.baseText('runData.emptyItemHint')))])],1),_c('td',{class:_vm.$style.tableRightMargin})])})],2):_c('table',{class:_vm.$style.table},[_c('thead',[_c('tr',[_vm._l((_vm.tableData.columns || []),function(column,i){return _c('th',{key:column},[_c('n8n-tooltip',{attrs:{"placement":"bottom-start","disabled":!_vm.mappingEnabled || _vm.showHintWithDelay,"open-delay":1000}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('img',{attrs:{"src":"/static/data-mapping-gif.gif"}}),_vm._v(" "+_vm._s(_vm.$locale.baseText('dataMapping.dragColumnToFieldHint'))+" ")]),_c('Draggable',{attrs:{"type":"mapping","data":_vm.getExpression(column),"disabled":!_vm.mappingEnabled},on:{"dragstart":_vm.onDragStart,"dragend":function (column) { return _vm.onDragEnd(column, 'column'); }},scopedSlots:_vm._u([{key:"preview",fn:function(ref){
var canDrop = ref.canDrop;
return [_c('div',{class:[_vm.$style.dragPill, canDrop ? _vm.$style.droppablePill : _vm.$style.defaultPill]},[_vm._v(" "+_vm._s(_vm.$locale.baseText('dataMapping.mapSpecificColumnToField', { interpolate: { name: _vm.shorten(column, 16, 2) }, }))+" ")])]}},{key:"default",fn:function(ref){
var _obj;

var isDragging = ref.isDragging;return [_c('div',{class:( _obj = {}, _obj[_vm.$style.header] = true, _obj[_vm.$style.draggableHeader] = _vm.mappingEnabled, _obj[_vm.$style.activeHeader] = (i === _vm.activeColumn || _vm.forceShowGrip) && _vm.mappingEnabled, _obj[_vm.$style.draggingHeader] = isDragging, _obj )},[_c('span',[_vm._v(_vm._s(column || ' '))]),(_vm.mappingEnabled)?_c('n8n-tooltip',{attrs:{"placement":"bottom-start","manual":true,"value":i === 0 && _vm.showHintWithDelay}},[(_vm.focusedMappableInput)?_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(
												_vm.$locale.baseText('dataMapping.tableHint', {
													interpolate: { name: _vm.focusedMappableInput },
												})
											)},slot:"content"}):_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('img',{attrs:{"src":"/static/data-mapping-gif.gif"}}),_vm._v(" "+_vm._s(_vm.$locale.baseText('dataMapping.dragColumnToFieldHint'))+" ")]),_c('div',{class:_vm.$style.dragButton},[_c('font-awesome-icon',{attrs:{"icon":"grip-vertical"}})],1)]):_vm._e()],1)]}}],null,true)})],1)],1)}),_c('th',{class:_vm.$style.tableRightMargin})],2)]),_c('Draggable',{ref:"draggable",attrs:{"tag":"tbody","type":"mapping","targetDataKey":"mappable","disabled":!_vm.mappingEnabled},on:{"dragstart":_vm.onCellDragStart,"dragend":_vm.onCellDragEnd},scopedSlots:_vm._u([{key:"preview",fn:function(ref){
											var canDrop = ref.canDrop;
											var el = ref.el;
return [_c('div',{class:[_vm.$style.dragPill, canDrop ? _vm.$style.droppablePill : _vm.$style.defaultPill]},[_vm._v(" "+_vm._s(_vm.$locale.baseText( _vm.tableData.data.length > 1 ? 'dataMapping.mapAllKeysToField' : 'dataMapping.mapSpecificColumnToField', { interpolate: { name: _vm.shorten(_vm.getPathNameFromTarget(el) || '', 16, 2) }, } ))+" ")])]}}])},[_vm._l((_vm.tableData.data),function(row,index1){return _c('tr',{key:index1},[_vm._l((row),function(data,index2){return _c('td',{key:index2,class:_vm.hasJsonInColumn(index2) ? _vm.$style.minColWidth : _vm.$style.limitColWidth,attrs:{"data-col":index2},on:{"mouseenter":_vm.onMouseEnterCell,"mouseleave":_vm.onMouseLeaveCell}},[(_vm.isSimple(data))?_c('span',{class:_vm.$style.value},[_vm._v(_vm._s([null, undefined].includes(data) ? ' ' : data))]):_c('n8n-tree',{attrs:{"nodeClass":_vm.$style.nodeClass,"value":data},scopedSlots:_vm._u([{key:"label",fn:function(ref){
											var _obj;

											var label = ref.label;
											var path = ref.path;return [_c('span',{class:( _obj = {}, _obj[_vm.$style.hoveringKey] = _vm.mappingEnabled && _vm.isHovering(path, index2), _obj[_vm.$style.draggingKey] = _vm.isDraggingKey(path, index2), _obj[_vm.$style.dataKey] = true, _obj[_vm.$style.mappable] = _vm.mappingEnabled, _obj ),attrs:{"data-target":"mappable","data-name":_vm.getCellPathName(path, index2),"data-value":_vm.getCellExpression(path, index2),"data-depth":path.length},on:{"mouseenter":function () { return _vm.onMouseEnterKey(path, index2); },"mouseleave":_vm.onMouseLeaveKey}},[_vm._v(_vm._s(label || _vm.$locale.baseText('runData.unnamedField')))])]}},{key:"value",fn:function(ref){
									var _obj;

									var value = ref.value;return [_c('span',{class:( _obj = {}, _obj[_vm.$style.nestedValue] = true, _obj[_vm.$style.empty] = _vm.isEmpty(value), _obj )},[_vm._v(_vm._s(_vm.getValueToRender(value)))])]}}],null,true)})],1)}),_c('td',{class:_vm.$style.tableRightMargin})],2)})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }