




















import mixins from 'vue-typed-mixins';

import { externalHooks } from '@/components/mixins/externalHooks';

export default mixins(externalHooks).extend({
	name: "SearchBar",
	props: ["value", "eventBus"],
	mounted() {
		if (this.$props.eventBus) {
			this.$props.eventBus.$on("focus", () => {
				this.focus();
			});
		}
		setTimeout(() => {
			this.focus();
		}, 0);

		this.$externalHooks().run('nodeCreator_searchBar.mount', { inputRef: this.$refs['input'] });
	},
	methods: {
		focus() {
			const input = this.$refs.input as HTMLInputElement;
			if (input) {
				input.focus();
			}
		},
		onInput(event: InputEvent) {
			const input = event.target as HTMLInputElement;
			this.$emit("input", input.value);
		},
		clear() {
			this.$emit("input", "");
		},
	},
});
